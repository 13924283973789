import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'
import Pagina from '../components/Pagina'
// import BannerLwart from '../components/BannerLwart'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import BackgroundImg from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useIntl } from 'gatsby-plugin-react-intl'
import parse from 'html-react-parser'

// CSS, SCSS
import 'swiper/css/swiper.css'
import './styles/nossa-essencia.scss'

// Assets
import iconMao from '../images/nossa-essencia/iconMao.svg'
import cracha from '../images/nossa-essencia/cracha.png'
import crachaEnglish from '../images/nossa-essencia/cracha-english.png'
import crachaEspanhol from '../images/nossa-essencia/cracha-espanhol.png'

const NossaEssencia = ({ data }) => {
  return (
    <>
      <Pagina pagina={data.pagina} />
      <Paralax paralax={data.paralax.childImageSharp.gatsbyImageData} paralaxMobile={data.paralaxMobile.childImageSharp.gatsbyImageData} />
      {/* <CuidamosFuturo {...data} /> */}
      <MundoPrecisa {...data} />
      <NossaHistoria {...data} />
      <GuiadaValores />
    </>
  )
}

const Paralax = ({ paralax, paralaxMobile }) => {
  const image = getImage(paralax)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(paralaxMobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  const intl = useIntl()

  return <>
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className="paralaxImage d-lg-block d-none"
      >
        <div className="container h-100 d-flex justify-content-end ">
          <div className=" h-100 d-flex align-items-center">
            <div className="w-100">
              <h1 className="pb-4 text-lwart-darkblue">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.paralax.title' }))}</h1>
              <p className="line-height-2 text-lwart-darkblue">
                {parse(intl.formatMessage({ id: 'paginas.nossa_essencia.paralax.p1_desktop' }))}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className="paralaxImage d-lg-none d-block"
      >
        <div className="container h-100 d-flex justify-content-end ">
          <div className=" h-100 d-flex pt-5">
            <div className="w-100 textoBanner">
              <h1 className="pb-0 pt-2 text-lwart-darkblue">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.paralax.title' }))}</h1>
              <p className="line-height-2 text-lwart-darkblue">
                {parse(intl.formatMessage({ id: 'paginas.nossa_essencia.paralax.p1_mobile' }))}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
    <div className="gradient-blue-to-green"></div>
  </>
}

// const CuidamosFuturo = () => {
//   const controls = useAnimation()
//   const [ref, inView] = useInView()

//   useEffect(() => {
//     if (inView) {
//       controls.start('visible')
//     }
//   }, [controls, inView])
//   return (
//     <motion.div ref={ref} animate={controls}
//       className="container text-center mb-5 mt-5 pb-2">
//       <motion.div
//         initial="hidden"
//         transition={{ duration: 2, delay: 0.5 }}
//         variants={{
//           visible: { opacity: 1 },
//           hidden: { opacity: 0 }
//         }} className="col-lg-12">
//         <div className="row justify-content-center m-0">
//           <img src={logo} className="w-75px h-75px" />
//         </div>
//       </motion.div>
//       <div className="row justify-content-center m-0">
//         <div className="align-center col-lg-8">
//           <motion.div
//             initial="hidden"
//             transition={{ duration: 2, delay: 0.5 }}
//             variants={{
//               visible: { opacity: 1 },
//               hidden: { opacity: 0 }
//             }} className="pb-3 pt-3">
//             <header>
//               <h2 className="lwartBlue font-mobile-custom-h2">Cuidamos do futuro transformando o hoje</h2>
//             </header>
//           </motion.div>
//           <p className="letter-space-1 line-height-normal font-mobile-custom-p">
//             Na Lwart Soluções Ambientais nós acreditamos que nada se perde,
//             tudo se transforma e que os resíduos são uma fonte de enorme valor. Por isso, acreditamos no poder da economia circular e em
//             sua potência para a preservação do planeta. Valorizamos a integridade, relações sustentáveis e sabemos da importância da interdependência entre pessoas,
//             empresas e a natureza.</p>
//           <p className="letter-space-1 line-height-normal font-mobile-custom-p mt-4">
//             Tudo o que nós fazemos aqui na Lwart é pensado da melhor forma, com uma abordagem inovadora, conhecimento e tecnologia. Tudo isso porque acreditamos no poder
//             da transformação como contribuição ao planeta de hoje e do futuro.</p>
//         </div>
//       </div>
//     </motion.div>
//   )
// }

const MundoPrecisa = (data) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.section ref={ref} animate={controls}
      className="bg-lwart-darkblue full custom-mh-essencia m-0">
      <GatsbyImage
        image={data.IconLwart.childImageSharp.gatsbyImageData}
        className="position-absolute col-lg-8 d-lg-block d-none iconLwart1" />
      <div className="container h-100">
        <div className="row m-0 p-lg-0 pt-5 pb-5 pr-3 pl-3 h-100">
          <GatsbyImage
            image={data.IconLwart.childImageSharp.gatsbyImageData}
            className="position-absolute col-lg-8 d-lg-block d-none iconLwart2" />
          <div className="Comptext position-relative float-lg-left col-lg-7 d-flex align-items-center justify-content-lg-end">
            <motion.h3
              initial="hidden"
              transition={{ duration: 1.5, delay: 0.5 }}
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 25 }
              }} className="lwartGreenTitle pr-4">
              {parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.title' }))}
            </motion.h3>
          </div>
          <motion.div
            initial="hidden"
            transition={{ duration: 1.5, delay: 1 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }} className="textSolucoes col-lg-4 text-left pl-lg-0 pr-lg-0">
            <div>
              <p className="letter-space-normal line-height-normal"><span>{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.p1' }))}</span></p>
              <p className="letter-space-normal line-height-normal"><span>{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.p2' }))}</span></p>
              <p className="letter-space-normal line-height-normal"><span>{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.p3' }))}</span></p>
              <p className="letter-space-normal line-height-normal"><span>{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.p4' }))}</span></p>
              <p className="letter-space-normal line-height-normal"><span>{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.mundo_precisa.p5' }))}</span></p>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  )
}

const NossaHistoria = (data) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const intl = useIntl()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const params = {
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 15
  }
  return <>
    <div className="gradient-blue-to-green"></div>
    <motion.section ref={ref} animate={controls}
      className="full my-0 mt-5 mb-5">
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-5 pl-lg-2">
            <img src={iconMao} className="rounded-circle bg-lwart-green3 mb-3 w-75px h-75px" alt="Icone de Mão" />
            <header>
              <motion.h2
                initial="hidden"
                transition={{ duration: 1, delay: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: -50 }
                }} className="mb-3 lwartBlue">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.nossa_historia.title' }))}</motion.h2>
            </header>
            <motion.div
              initial="hidden"
              transition={{ duration: 1, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}>
              <p className="mb-3 line-height-1">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.nossa_historia.p1' }))}</p>
              <p className="mb-3 line-height-1">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.nossa_historia.p2' }))}</p>
              <p className="mb-3 line-height-1">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.nossa_historia.p3' }))}</p>
              <p className="mb-3 line-height-1">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.nossa_historia.p4' }))}</p>
            </motion.div>
          </div>
          <div className="col-lg-6 carouselEssencia pt-5">
            <Swiper {...params}>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img1.childImageSharp.gatsbyImageData} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img2.childImageSharp.gatsbyImageData} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img3.childImageSharp.gatsbyImageData} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img4.childImageSharp.gatsbyImageData} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img5.childImageSharp.gatsbyImageData} />
              </div>
              <div className="carousel-sm-swipper-item">
                <GatsbyImage image={data.img6.childImageSharp.gatsbyImageData} />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </motion.section>
  </>
}

const GuiadaValores = () => {
  const intl = useIntl()

  return (
    <>
      <div className="gradient-blue-to-green"></div>
      <section className="bg-lwart-lightblue2 full m-0">
        <div className="container pb-lg-0 pb-5 px-0">
          <div className="row m-0 px-4">
            <div className="col-lg-5 text-lg-right text-center">
              {intl.locale === 'pt'
                ? <img src={cracha} alt='' className="Cracha" />
                : intl.locale === 'en'
                  ? <img src={crachaEnglish} alt='' className="Cracha" />
                  : <img src={crachaEspanhol} alt='' className="Cracha" />
              }
            </div>
            <div className="textSolucoes col-lg-5 my-lg-5 my-0 px-0 px-lg-3 text-lg-left text-white">
              <div>
                <h2 className="text-white mb-3">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.guiada_valores.title' }))}</h2>
                <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.guiada_valores.p1' }))}
                </p>
                <p className="text-white">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.guiada_valores.p2' }))}
                </p>
                <p className="text-white mb-0">{parse(intl.formatMessage({ id: 'paginas.nossa_essencia.guiada_valores.p3' }))}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="gradient-blue-to-green"></div>
    </>
  )
}

export default NossaEssencia

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/nossa-essencia/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "nossa-essencia/Banner-essencia.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "nossa-essencia/Banner-essencia-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  IconLwart: file(relativePath: {eq: "nossa-essencia/logo-essencia.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 750, height: 780, layout: FIXED)
    }
  }
  img1: file(relativePath: {eq: "nossa-essencia/img1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
  img2: file(relativePath: {eq: "nossa-essencia/img2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
  img3: file(relativePath: {eq: "nossa-essencia/img3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
  img4: file(relativePath: {eq: "nossa-essencia/img4.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
  img5: file(relativePath: {eq: "nossa-essencia/img5.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
  img6: file(relativePath: {eq: "nossa-essencia/img6.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 620, height: 450, layout: CONSTRAINED)
    }
  }
}
`
